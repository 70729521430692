@charset "UTF-8";

@font-face {
  font-family: 'MFont';
  font-style: normal;
  font-weight: 100;
  src: url('/fonts/mplus-1c-thin.eot');
  /* IE9 Compat Modes */
  src: url('mplus-1c-thin.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/mplus-1c-thin.woff') format('woff'), /* Modern Browsers */
  url('/fonts/mplus-1c-thin.ttf') format('truetype');
  /* Safari, Android, iOS */
}

@font-face {
  font-family: 'MFont';
  font-style: normal;
  font-weight: 200;
  src: url('/fonts/mplus-1c-light.eot');
  src: url('/fonts/mplus-1c-light.eot?#iefix') format('embedded-opentype'),
  url('/fonts/mplus-1c-light.woff') format('woff'),
  url('/fonts/mplus-1c-light.ttf') format('truetype');
}

@font-face {
  font-family: 'MFont';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/mplus-1c-regular.eot');
  src: url('/fonts/mplus-1c-regular.eot?#iefix') format('embedded-opentype'),
  url('/fonts/mplus-1c-regular.woff') format('woff'),
  url('/fonts/mplus-1c-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'MFont';
  font-style: normal;
  font-weight: 500;
  src: url('/fonts/mplus-1c-medium.eot');
  src: url('/fonts/mplus-1c-medium.eot?#iefix') format('embedded-opentype'),
  url('/fonts/mplus-1c-medium.woff') format('woff'),
  url('/fonts/mplus-1c-medium.ttf') format('truetype');
}

@font-face {
  font-family: 'MFont';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/mplus-1c-bold.eot');
  src: url('/fonts/mplus-1c-bold.eot?#iefix') format('embedded-opentype'),
  url('/fonts/mplus-1c-bold.woff') format('woff'),
  url('/fonts/mplus-1c-bold.ttf') format('truetype');
}

@font-face {
  font-family: 'MFont';
  font-style: normal;
  font-weight: 900;
  src: url('/fonts/mplus-1c-black.eot');
  src: url('/fonts/mplus-1c-black.eot?#iefix') format('embedded-opentype'),
  url('/fonts/mplus-1c-black.woff') format('woff'),
  url('/fonts/mplus-1c-black.ttf') format('truetype');
}
@font-face {
  font-family: 'Noto Serif Japanese';
  font-style: normal;
  font-weight: 200;
  src: url('/fonts/NotoSerifCJKjp-ExtraLight.eot');
  src: url('/fonts/NotoSerifCJKjp-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('/fonts/NotoSerifCJKjp-ExtraLight.woff') format('woff'),
    url('/fonts/NotoSerifCJKjp-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Serif Japanese';
  font-style: normal;
  font-weight: 300;
  src: url('/fonts/NotoSerifCJKjp-Light.eot');
  src: url('/fonts/NotoSerifCJKjp-Light.eot?#iefix') format('embedded-opentype'),
    url('/fonts/NotoSerifCJKjp-Light.woff') format('woff'),
    url('/fonts/NotoSerifCJKjp-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Serif Japanese';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/NotoSerifCJKjp-Regular.eot');
  src: url('/fonts/NotoSerifCJKjp-Regular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/NotoSerifCJKjp-Regular.woff') format('woff'),
    url('/fonts/NotoSerifCJKjp-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Serif Japanese';
  font-style: normal;
  font-weight: 500;
  src: url('/fonts/NotoSerifCJKjp-Medium.eot');
  src: url('/fonts/NotoSerifCJKjp-Medium.eot?#iefix') format('embedded-opentype'),
    url('/fonts/NotoSerifCJKjp-Medium.woff') format('woff'),
    url('/fonts/NotoSerifCJKjp-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Serif Japanese';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/NotoSerifCJKjp-SemiBold.eot');
  src: url('/fonts/NotoSerifCJKjp-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/NotoSerifCJKjp-SemiBold.woff') format('woff'),
    url('/fonts/NotoSerifCJKjp-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Serif Japanese';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/NotoSerifCJKjp-Bold.eot');
  src: url('/fonts/NotoSerifCJKjp-Bold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/NotoSerifCJKjp-Bold.woff') format('woff'),
    url('/fonts/NotoSerifCJKjp-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Serif Japanese';
  font-style: normal;
  font-weight: 900;
  src: url('/fonts/NotoSerifCJKjp-Black.eot');
  src: url('/fonts/NotoSerifCJKjp-Black.eot?#iefix') format('embedded-opentype'),
    url('/fonts/NotoSerifCJKjp-Black.woff') format('woff'),
    url('/fonts/NotoSerifCJKjp-Black.ttf') format('truetype');
}
p {
  margin: 0;
  padding: 0;
  text-align: left;
  line-break: strict;
}

a {
  color: #0268d0;
  // cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;

  }
}
