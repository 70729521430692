@charset "UTF-8";
@import "../../common/reset";
@import "../../common/font";
@import "../../common/config";
@import "../../common/common";
@import "../../common/bread";
@import "../../module/mixin";
@import "../module/variables";
@import "../layout/header";
@import "../layout/footer";
@import "../layout/pagetop";


.icon {

    &:after {
      background: url("#{$baseImgDir}/icon.png") center center/contain no-repeat;
    }
  }
  
  .icon2 {
  
    &:after {
      background: url("#{$baseImgDir}/icon_white.png") center center/contain no-repeat;
    }
  }