@charset "UTF-8";

.section {
    &__inner {
        width: 1020px;
        margin: 0 auto;
        padding: 60px 0 80px;

        @include sp() {
            width: 100%;
            padding: 40px 10px;
        }
    }

    &__title {
        font-size: 40px;
        line-height: 48px;
        margin-bottom: 60px;
        font-weight: 300;
        @include df();

        @include sp() {
            @include o-font(24);
            line-height: 38px;
            margin-bottom: 40px;
            align-items: flex-start;
            text-align: left;
        }
    }

    p {
        font-weight: 300;
        line-height: 34px;
        font-size: 16px;
        margin: 0 auto;

        @include sp() {
            width: 100%;
            @include o-font(14);
            line-height: 28px;
        }
    }
}

.text {
    width: 800px;

    @include sp() {
        width: 100%;
    }
}

.icon {
    width: 60px;
    height: 60px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    @include sp() {
        width: 30px;
        height: 30px;
        margin-right: 6px;
        margin-top: 6px;
    }

    &:after {
        display: block;
        content: "";
        width: 60px;
        height: 60px;

        @include sp() {
            width: 30px;
            height: 30px;
        }
    }
}

.icon2 {
    width: 60px;
    height: 60px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    @include sp() {
        width: 30px;
        height: 30px;
        margin-right: 6px;
        margin-top: 6px;
    }

    &:after {
        display: block;
        content: "";
        width: 60px;
        height: 60px;

        @include sp() {
            width: 30px;
            height: 30px;
        }
    }
}

.newTabIcon {
    width: 30px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    &:after {
        display: block;
        content: "";
        width: 30px;
        height: 30px;
    }
}

input[type='text'],
input[type='email'],
input[type='tel'] {
    height: 50px;
    padding: 10px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    color: #333;
    border-radius: 10px;
    border: solid 1px #ccc;

    @include sp() {
        height: 40px;
        border-radius: 0;
        @include o-font(15);
        line-height: 21px;
        padding: 6px 10px;
    }
}

textarea {
    resize: none;
    border: solid 1px #cccccc;
    padding: 10px 20px;
    font-size: 20px;
    border-radius: 10px;
    font-weight: 300;

    @include sp() {
        border-radius: 0;
        @include o-font(15);
        line-height: 21px;
        padding: 6px 10px;
    }
}

input[type="checkbox"],
input[type="radio"] {
    appearance: none;
    opacity: 0;
    position: absolute;
}

.label__checkbox {
    position: relative;
    display: inline-block;
    padding: 3px 3px 3px 42px;
    cursor: pointer;
    transition: all .2s;

    @include sp() {
        @include o-font(15);
        line-height: 21px;
        padding: 3px 3px 3px 32px;
    }
}

.label__checkbox::before,
.label__checkbox::after {
    position: absolute;
    content: '';
    -webkit-transition: all .2s;
    transition: all .2s;
}

.label__checkbox::before {
    top: 50%;
    left: 0;
    width: 30px;
    height: 30px;
    margin-top: -15px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;

    @include sp() {
        width: 20px;
        height: 20px;
    }
}

.label__checkbox::after {
    opacity: 0;
    top: 50%;
    left: 6px;
    width: 19px;
    height: 6px;
    margin-top: -4px;
    border-left: 2px solid #494a4a;
    border-bottom: 2px solid #494a4a;
    -webkit-transform: rotate(-45deg) scale(.5);
    transform: rotate(-45deg) scale(.5);

    @include sp() {
        left: 5px;
        width: 11px;
        height: 4px;
        margin-top: -8px;
    }
}

.label__checkbox:hover::before {
    background: #fff;
}

input[type="checkbox"]:checked+.label__checkbox::before,
input[type="radio"]:checked+.label__checkbox::before {
    background: #fff;
}

input[type="checkbox"]:checked+.label__checkbox::after,
input[type="radio"]:checked+.label__checkbox::after {
    opacity: 1;
    -webkit-transform: rotate(-45deg) scale(1);
    transform: rotate(-45deg) scale(1);
}

.dlAlert {
    &__bg {
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        left: 0;
        display: none;
    }

    &__box {

        background: white;
        display: flex;
        flex-direction: column;
        padding: 40px;
        border-radius: 10px;

        p {
            padding: 10px;
            flex: 1;
        }
    }

    &__btn {

        margin-top: 30px;
        width: 100%;
        height: 70px;
        display: flex;
        align-content: center;
        justify-content: space-around;
        padding: 10px;

        button {
            flex: 1;
            margin: 0 10px;
            color: white;
            border-radius: 5px;
        }

        .ok {
            background: #19bf29;
        }

        .no {
            background: #ff5d5d;
        }
    }
}

.space {
    width: 18px;
    display: inline-block;
}

.titleNum {
    font-size: 70px;
    font-weight: 700;
    line-height: 70px;
    margin-right: 10px;

    @include sp() {
        @include o-font(50);
        line-height: 50px;
    }
}

.spBr {
    display: none;

    @include sp() {
        display: inline;
    }
}

.marker {
    background: linear-gradient(transparent 75%, rgba(246, 166, 35, 0.35) 0%);
    line-height: 20px;
}
.marker_green {
    background: linear-gradient(transparent 75%,rgb(79, 213, 139) 0);
    line-height: 20px;
}
.marker_purple {
    background: linear-gradient(transparent 75%,rgb(208, 110, 215) 0);
    line-height: 20px;
}
.strong {
    font-size: 22px;
    line-height: 36px;
    font-weight: 400;

    @include sp() {
        @include o-font(18);
        line-height: 32px;
    }
}

.error {
    color: red;
    text-align: center;
    padding: 10px 0;
}
.border{
    width: 100%;
    height: 1px;
    display: block;
    background: #aaa;
}