@charset "UTF-8";

.pageTop {
  width: 100px;
  background: #444;
  height: 80px;
  border-radius: 10px;
  @include df("ce","fs");
  position: fixed;
  bottom: 50px;
  right: 20px;
  flex-direction: column;
  padding-top: 10px;
  box-sizing: border-box;
  p {
    color: white;
    font-weight: 300;
  }
  &__text {
    font-size: 14px;
  }
  &__arrow {
    font-size: 30px;
  }
  &:hover {
    text-decoration: none;
    opacity: 0.9;
  }

}