@charset "UTF-8";

.footer {
  text-align: center;
  padding: 20px 0;
  background: #fff;
  border-top: solid 1px #ddd;
  
  &__inner {
    padding: 0 20px;
    @include df("ce","sb");
    @include sp() {
      flex-wrap: wrap;
    }
  }
  
  &__menu {
    // width: 600px;
    @include df("ce","sa");
    @include sp() {
      width: 100%;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    li {
      padding: 0 15px;
      @include sp() {
        width: 50%;
        padding: 6px 0;
        @include df();
      }
    }
    a {
      color: #333;
      height: 24px;
      @include df();
      font-size: 14px;
      line-height: 21px;
      font-weight: 300;
      text-decoration: none;
      @include sp() {
        @include o-font(13);
        line-height: 19px;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__copy {
    padding: 10px 0;
    color: #333;
    text-align: center;
    font-size: 12px;
    line-height: 26px;
    @include sp(){
      @include o-font(10);
      line-height: 14px;
      width: 100%;
      text-align: center;
      margin-top: 20px;
    }
  }

}
