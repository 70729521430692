@charset "UTF-8";

.header {
  width: 100%;
  text-align: center;
  // background: rgba(0,0,0,0);
  background: #fff;
  padding: 4px 0;
  border-bottom: solid 1px #ddd;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  @include sp() {
    padding: 6px 0;
    background: #fff;
    height: 78px;
  }

  &__inner {
    margin: 0 auto;
    padding: 0 20px;
    @include df("ce", "sb");

    @include sp() {
      width: 100%;
      padding: 0 10px;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__title {
    font-size: 10px;
    color: #999;
    font-weight: 400;
    margin-top: 0;
    color: rgb(144, 144, 144);
    margin-bottom: 2px;

    @include sp() {
      margin: 0 0 4px 0;
    }
  }

  &__logoBlock {
    text-align: left;
    margin-right: 20px;

    a {
      display: inline-block;
    }
  }

  &__logo {
    height: 40px;

    @include sp() {
      height: 30px;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &__menu {
    @include df("ce", "fs");

    @include sp() {
      display: none;
    }

    li {
      padding: 10px 15px;
      margin: 0 4px;
      box-sizing: border-box;
      position: relative;

      a {
        color: #333;
        font-size: 14px;
        font-weight: 300;
        position: relative;
        transition: color 0.3s;

        &:hover {
          text-decoration: none;
          color: #f99c05;
        }

        &.current {
          color: #f99c05;
        }
      }
    }
  }

  &__btn {
    margin: 0 10px;

    a {
      display: block;
      text-align: left;
      background: #f99c05;
      color: white;
      border-radius: 40px;
      font-size: 14px;
      border: solid 1 px white;
      height: 30px;
      width: 150px;
      @include df();

      &:nth-child(2) {
        margin-top: 6px;
      }

      @include sp() {
        display: none;
      }

      &:hover {
        background: #f5b44c;
        text-decoration: none;
      }

      i {
        margin-right: 5px;
      }
    }
  }

  &__tel {
    @include sp() {
      display: none;
    }

    &__txt {
      font-size: 12px;
      color: white;

      i {
        margin-right: 5px;
      }
    }

    &__number {
      font-size: 30px;
      font-weight: 700;
      color: #fcce27;
    }
  }

  &__globalMenu {
    @include df("ce", "sa");
    flex: 1;

    @include sp() {
      display: none;
    }

    &>li {
      height: 100%;
      color: white;
      position: relative;
      font-size: 14px;

      &.accordion__title {
        cursor: pointer;
      }

      .accordion__menu {
        display: none;
        position: absolute;
        width: auto;
        background: #2582b3;
        padding: 10px 0;

        li {
          padding: 10px 0;
          width: 160px;
          font-size: 14px;
        }
      }

      a {
        text-align: left;
        width: 100%;
        display: block;
        color: white;
        padding: 0 10px;
      }

      p {
        color: white;
        text-align: left;
        width: 100%;
        padding: 0 10px;
      }
    }
  }

  .drawer__toggle {
    display: none;
    position: relative;
    height: 24px;
    width: 30px;
    margin-right: 20px;

    @include sp() {
      display: block;
      margin-right: 0;
    }

    span {
      width: 30px;
      display: block;
      background: #333;
      height: 4px;
      -webkit-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out;
      position: absolute;
    }

    .line1 {
      top: 0;
      right: 0;
    }

    .line2 {
      top: 10px;
      right: 0;
    }

    .line3 {
      top: 20px;
      right: 0;
    }

    &.active {
      .line1 {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 10px;
      }

      .line2 {
        opacity: 0;
      }

      .line3 {
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
        top: 10px;
      }
    }
  }

  .drawer__menu {
    display: none;
    position: absolute;
    width: 100%;
    height: 100vh;
    background: white;
    top: 78px;
    right: 0;
    z-index: 1000;

    &.active {
      right: 0;
    }

    ul {
      li {
        a {
          display: block;
          width: 100%;
          padding: 16px 0;
          color: #333;
          font-weight: 300;
        }
      }
    }

    &__list {
      li {
        &:not(:last-child) {
          border-bottom: solid 1px #ccc;
        }
      }
    }
  }
}