@charset "UTF-8";
@import "common/base";

body {
  text-align: center;
}

.visual {
  padding-top: 70px;
  overflow: hidden;
  height: 600px;
  width: 100%;

  // ul {
  //   width: 100%;
  //   height: 100%;
  // }

  .v-item {
    width: 100%;
    height: 600px;
    position: relative;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    a {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  h2 {
    position: absolute;
    font-size: 3.5vw;
    top: 60px;
    left: 40px;
    text-align: left;
    line-height: 80px;
    color: #333;
    font-family: Georgia, "Noto Serif Japanese", serif;

    @include sp() {
      width: 100%;
      @include o-font(24);
      line-height: 42px;
      left: 0px;
      top: 20px;
      padding: 0 10px;
      text-align: center;
    }

    span {
      color: red;
    }
  }

  h3 {
    position: absolute;
    font-size: 1.8vw;
    line-height: 26px;
    top: 360px;
    left: 40px;
    text-align: left;
    width: 600px;
    line-height: 50px;
    font-weight: 500;
    color: #333;

    @include sp() {
      width: 100%;
      left: 0;
      top: 130px;
      padding: 0 20px;
      @include o-font(14);
      line-height: 28px;
      text-align: center;
    }

    span {
      color: red;
      font-size: 1.9vw;

      @include sp() {
        @include o-font(16);
        line-height: 30px;
      }
    }
  }

  .v-mynumber {
    background-image: url(../img/common/visual_under_bg_cloud.jpg);

    h3 {
      top: 310px;

      @include sp() {
        top: 130px;
      }
    }
  }

  .v-idoc {
    background-image: url(../img/common/visual_under_bg_doc.jpg);

    @include sp() {
      background-image: url(../img/common/visual_under_bg_doc_sp.jpg);
    }

    h3 {
      top: 330px;

      @include sp() {
        top: 160px;
      }
    }
  }

  .v-map {
    background-image: url(../img/common/key_map.jpg);

    @include sp() {
      background-image: url(../img/common/key_map_sp.jpg);
    }

    h3 {
      top: 310px;

      @include sp() {
        top: 130px;
      }
    }
  }

  &__inner {
    height: 100%;
    margin: 0 auto;
    width: 1280px;
    position: relative;

    @include sp() {
      width: 100%;
    }
  }

  // .mask {
  //   width: 100%;
  //   height: 100%;
  //   background: rgba(255, 255, 255, 0.3);
  // }

  .kv_pc {
    width: 37vw;
    position: absolute;
    top: 70px;
    right: 20px;

    @include sp() {
      top: 300px;
      left: calc(50vw - 35vw);
      width: 70vw;
    }
  }
}

.newsSection {
  background: #525252;

  .section__title {
    color: white;
    margin-bottom: 60px;
  }

  &__list {

    width: 800px;
    margin: 0 auto;

    @include sp() {
      width: 100%;
      margin-top: 20px;
    }

    li {
      padding: 10px 0;
      @include df("fs", "fs");

      @include sp() {
        @include o-font(14);
        line-height: 20px;
        flex-direction: column;
      }

      p {
        color: white;
      }
    }

    & &__title {
      margin-right: 40px;
    }

    & &__text {
      @include sp() {
        margin: 10px 0 0 0;
      }
    }
  }
}

.mainSection {
  background: $baseColor;

  .section__inner {
    padding: 80px 0;

    @include sp() {
      padding: 40px 10px;
    }
  }

  .section__title {
    color: #fff;
  }

  p {
    color: white;
  }
}

.productSection {
  .svc {
    display: block;
    width: 100%;
    margin: 40px 0 0;
    text-align: left;
    background: white;
    padding: 60px;
    border-radius: 10px;

    @include sp() {
      padding: 20px;
    }

    &__head {
      @include df();

      @include sp() {
        flex-direction: column;
      }
    }

    &__img {
      width: 330px;
      min-width: 330px;
      margin-right: 20px;

      @include sp() {
        height: 60px;
        width: auto;
        min-width: auto;
      }
    }

    &__title {
      font-size: 22px;
      line-height: 38px;
      font-weight: 500;
      margin-left: 30px;

      @include sp() {
        @include o-font(16);
        line-height: 30px;
        margin: 30px 0 0 0;
      }
    }

    &__contents {
      margin-top: 60px;
      width: 100%;

      @include sp() {
        margin: 30px 0 0 0;
      }

    }

    &__link {
      display: inline-block;
      width: 200px;
      background: #19bf29;
      padding: 10px 0;
      text-align: center;
      color: white;
      text-decoration: none;
      font-size: 14px;
      line-height: 20px;

      &__box {
        margin-top: 20px;
        width: 100%;
        text-align: right;
      }
    }

    .linkBtn {
      width: 300px;
      height: 50px;
      border: solid 1px orange;
      @include df();
      border-radius: 10px;
      color: orange;
      font-weight: 300;
      letter-spacing: 2px;
      margin: 60px auto 0;

      @include sp() {
        width: 100%;
        margin: 30px auto 0;
        @include o-font(14);
        line-height: 28px;
      }

      &:hover {
        background: orange;
        color: white;
        text-decoration: none;
      }
    }
  }
}