@charset "UTF-8";

.bread {
  padding: 10px 20px;
  text-align: left;
  font-size: 14px;
  line-height: 22px;
  margin-top: 70px;
  @include sp(){
    padding: 10px 6px;
    font-size: 12px;
    line-height: 18px;
  }
  span{
    font-weight: 300;
  }
  &__arrow{
    margin: 0 10px;
    @include sp(){
      margin: 0 2px;
    }
  }
}
