@charset "UTF-8";
@import "../module/mixin";

.df-fs-sb{
    @include df("fs","sb");
}
.df-fs-sa{
    @include df("fs","sa");
}
.df-ce-sa{
    @include df("ce","sa");
}
.pc{
    @include sp(){
        display: none;
    }
}
.oh{
    overflow: hidden;
}

.fr{
    float: right;
}
.fl{
    float: left;
}
.mb60{
    margin-bottom: 60px;
}
.ta-c{
    text-align: center;
}